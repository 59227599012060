class AppInstallPrompt {
  constructor() {
    this.$promptEl = document.querySelector('.prompt-app');
    this.$closePrompt = document.querySelector('.cls-prompt');
    this.$installLink = document.querySelector('.prompt-app__link')
    this.initialShowCheck()
  }

  initialShowCheck() {
    if(this.$promptEl) {
      if (!document.cookie.includes('HiikerAppInstallPrompt')) {
        this.$promptEl.classList.remove('hide')
        this.bindPromptEvents()
      } else {
        this.$promptEl.classList.add('hide')
      }
    }
  }

  bindPromptEvents() {
    const self = this;
    this.$closePrompt.addEventListener('click', function(){
      self.setCookie();
      self.$promptEl.classList.add('hide');
    })

    this.$installLink.addEventListener('click', function() {
      self.setCookie();
      self.$promptEl.classList.add('hide');
    })
  }

  setCookie() {
    var now = new Date();
    now.setMonth( now.getMonth() + 1 );
    document.cookie="name=" + 'HiikerAppInstallPrompt';
    document.cookie = "expires=" + now.toUTCString() + ";"
  }
}

if( document.readyState !== 'loading' ) {
  window.appInstallPrompt = new AppInstallPrompt()
} else {
  document.addEventListener('DOMContentLoaded', function () {
    window.appInstallPrompt = new AppInstallPrompt()
  });
}
