import Vue from "vue";
import HomeSearch from "./HomeSearch.vue";

if (document.querySelector('.home-search-container')) {
  window.vue = new Vue({
    render: (createElement) => {
      return createElement(HomeSearch)
    },
    async mounted() {
    },
    methods: {
    }
  }).$mount('.home-search-container');
}
