import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy.js';

(function (doc) {
  if( document.readyState !== 'loading' ) {
     svg4everybody({});
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      svg4everybody({});
    });
  }
}(document));
