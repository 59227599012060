if (window.window.feedback_REASONS_AND_FOLLOW_UP_QUESTIONS) {
  const feedbackRadios = Array.prototype.slice.call(document.querySelectorAll('input[name="feedback[reason_for_cancellation]"]'));
  feedbackRadios.forEach((radio) => {
    radio.addEventListener('change', (event) => {
      if(window.window.feedback_REASONS_AND_FOLLOW_UP_QUESTIONS[event.target.value]) {
        console.log('show follow up question');
        const label = document.querySelector('label[for="feedback_comments"]');
        label.innerHTML = window.window.feedback_REASONS_AND_FOLLOW_UP_QUESTIONS[event.target.value];
      }
    });
  });
}